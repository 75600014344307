<template>
  <main>
    <div class="flex flex-col items-center mt-0 md:mt-2 lg:mt-9">
      <h1 class="text-center text-xl xs:text-3xl sm:text-5xl lg:text-8xl font-black max-w-xl mx-auto leading-snug text-neutral-100">Propeller Festival</h1>
      <!-- <UICountdown :goalTime="festivalDate!.start_date" class="mb-32" /> -->
      <UButton color="prop-red" class="text-sm xs:text-base lg:text-lg uppercase tracking-wider px-4 lg:px-12 font-semibold" @click="toTickets"
        >Jetzt Tickets sichern!</UButton
      >
    </div>
    <div class="py-8 mt-40 md:mt-48 lg:mt-44 xl:mt-72 2xl:mt-80">
      <HomeKulturNight />
      <HomeFestivalNight class="mb-12" />
      <HomeTheWeTheFestival />
    </div>
  </main>
</template>

<script setup lang="ts">
import FestivalNight from '~/components/home/FestivalNight.vue'

definePageMeta({
  title: 'Propellerfestival 2024',
  description: 'Das Propellerfestival 2024 findet vom 15. bis 16. Juni 2024 statt. Sei dabei und erlebe ein unvergessliches Wochenende!',
  layout: 'startpage',
})

// const isGetError = ref(false)
// const festivalDate: Ref<FestivalDate | null> = ref(null)

// const { data, error, pending } = await useFetch<FestivalDate>('/api/festivaldate', {
//   method: 'GET',
// })

// if (error.value) {
//   isGetError.value = true
// }

// if (data.value) {
//   festivalDate.value = data.value
// }
function toTickets() {
  return navigateTo('/ticket')
}
</script>
