<template>
  <div class="py-6">
    <div class="container">
      <h2 class="text-darkestRed text-lg">Freitag 14. Juni 2024</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <h2 class="mt-0 break-all text-5xl tracking-wide">Kulturabend</h2>
        </div>
        <div class="text-balance">
          Am Freitagabend steht Bernd Kohlhepp bei uns auf dem Hofgut Dürrenberg mit seinem neuen Programm „Hämmerle räumt auf“ auf der Bühne. Beginn ist um 20
          Uhr. Karten gibt es unter:
          <NuxtLink
            to="https://www.reservix.de/tickets-bernd-kohlhepp-meets-propellerfestival-haemmerle-raeumt-auf-in-wangen-im-allgaeu-hofgut-durrenberg-am-14-6-2024/e2242962"
            target="_blank"
            >www.reservix.de</NuxtLink
          >
          oder an der Abendkasse. Der Kulturabend ist als OpenAir geplant, es gibt eine Schlechtwetteralternative. Mehr Infos unter:
          <NuxtLink to="/kulturstadel">Kulturstadel</NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style>
.wave1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.wave1 svg {
  position: relative;
  display: block;
  width: calc(124% + 1.3px);
  height: 93px;
}

.wave1 .shape-fill {
  fill: #ffffff;
}
</style>
